/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  FC, useState, useEffect, useContext,
} from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import QueryString from 'query-string';

import Layout from '@/common/ui/template/Layout';
import Header from '@/newcar/ui/common/organism/Header';
import CarmoBreadcrumb from '@/newcar/ui/lineup/organism/CarmoBreadcrumb';
import CarmoFooter from '@/newcar/ui/lineup/organism/CarmoFooter';
import Footer from '@/newcar/ui/common/organism/Footer';

import CarModelPanel from '@/newcar/ui/lineup/organism/CarModelPanel';
import ModalDetailCondition from '@/newcar/ui/lineup/organism/ModalDetailCondition';
import SearchResultHeader from '@/newcar/ui/lineup/organism/SearchResultHeader';
import SearchResultDesktop from '@/newcar/ui/lineup/organism/SearchResultDesktop';
import Accordion from '@/newcar/ui/lineup/molecule/Accordion';
import SearchResultFooter from '@/newcar/ui/lineup/organism/SearchResultFooter';
import EmptyStateLineupPage from '@/newcar/ui/lineup/organism/EmptyStateLineupPage';
import CarModelPanelSkeletonScreen from '@/newcar/ui/lineup/organism/CarModelPanelSkeletonScreen';
import ReviewsLineup from '@/newcar/ui/lineup/organism/ReviewsLineup';
import ScrollToTop from '@/common/ui/organism/ScrollToTop';

// 上部バナー
import ab202408Banner from '@/newcar/ui/lineup/img/ab2024008Banner.png';

// 三冠オリックス画像
import tripleCrown from '@/newcar/ui/lineup/img/triple_crown.svg';
import tripleCrownCarmoLogo from '@/newcar/ui/lineup/img/triple_crown_carmo_logo.jpg';
import reasonRecommend from '@/newcar/ui/lineup/img/reasonRecommend.svg';
import reasonSupport from '@/newcar/ui/lineup/img/reasonSupport.svg';
import reasonCost from '@/newcar/ui/lineup/img/reasonCost.svg';
import reasonRecommendSp from '@/newcar/ui/lineup/img/reasonRecommendSp.svg';
import reasonSupportSp from '@/newcar/ui/lineup/img/reasonSupportSp.svg';
import reasonCostSp from '@/newcar/ui/lineup/img/reasonCostSp.svg';
import orix from '@/newcar/ui/lineup/img/orix_logo.jpg';
// util
import { GTM } from '@/common/util/gtm';
import CARMO_CONST from '@/common/util/const';
import CARMO_UTIL from '@/common/util/util';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
// import URI from '@/newcar/util/uri';
import {
  SeoText, BASE_TEXT, seoDataExists, fetchData,
} from '@/newcar/util/const/seo';
// core
import CarDigest from '@/common/core/model/carDigest';
import SearchCondition from '@/newcar/core/model/searchCondition';
import SearchCarStoreContext from '@/newcar/ui/common/context/searchCarStoreContext';

import * as styles from './index.module.styl';

type DigestPageContext = {
  carDigests: CarDigest[];
}

const TemplatePage: FC<PageProps<{}, DigestPageContext>> = ({ pageContext, location }) => {
  const {
    carDigests,
  } = pageContext;

  // カルモ最安車のID（複数可）を取得
  const publishCars = carDigests.filter(
    (car) => car.publishStatus === NEWCAR_CONST.PUBLISH.STATUS.SALE,
  );
  const minPrice = publishCars.sort(
    (a, b) => a.taxedMinPrice11 - b.taxedMinPrice11,
  )[0].taxedMinPrice11;
  const minPriceCarIds = publishCars
    .filter((car) => car.taxedMinPrice11 === minPrice)
    .map((car) => car.id__normalized);

  // storeにcarDigests格納
  const searchCarStore = useContext(SearchCarStoreContext);
  searchCarStore.setAllCarDigests(carDigests);

  const [resultList, setResultList] = useState<CarDigest[]>(searchCarStore.resultList);
  const [isSearched, setIsSearched] = useState(false);
  const [headerOrder, setHeaderOrder] = useState(NEWCAR_CONST.SORT.PRICE);
  const makerId = CARMO_CONST.MAKER.ID.UNSELECTED;
  const bodyType = CARMO_CONST.BODY_TYPE.ID.UNSELECTED;
  // SEOテキスト用
  const seoBaseText = BASE_TEXT;
  const [seoData, setSeoData] = useState<SeoText>({
    url: '',
    topText: '',
    headLine: '',
    body: '',
  });

  const onUrlChange = async () => {
    // この関数内で変更して使用するもの useStateはワンテンポ遅いため
    let orderby: NEWCAR_CONST.SORT = NEWCAR_CONST.SORT.PRICE;
    // パラメーターによりmetaタグとリストの出し分け
    // setResultList([]);
    const searchParams = location.search;
    const query = QueryString.parse(searchParams, { arrayFormat: 'comma', parseNumbers: true });
    // 並び順のデフォルトは値段
    if (query.order) {
      // パラメーターがある場合
      if (query.order === NEWCAR_CONST.SORT.POP) {
        // 人気順parameterに部分一致のときの処理
        orderby = NEWCAR_CONST.SORT.POP;
      } else if (query.order === NEWCAR_CONST.SORT.ATOZ) {
        // 50音順parameterに部分一致のときの処理
        orderby = NEWCAR_CONST.SORT.ATOZ;
      }
    }
    setHeaderOrder(orderby);

    // 初期化
    const condition: SearchCondition = new SearchCondition();
    condition.orderBy = orderby;
    // クエリを文字の配列に整形
    const queryConverted = [query.opt].flat().map((opt) => opt?.toString());
    if (query.word) {
      // フリーワード検索の場合、デコードして格納
      condition.word = decodeURIComponent(query.word.toString());
    } else {
      if (query.opt) {
        // その他条件は文字列から振り分け
        if (queryConverted
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_HYBRID)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_HYBRID);
        }
        if (queryConverted.map((opt) => opt?.toString())
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_4WD)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_4WD);
        }
        if (queryConverted
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_MT)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_MT);
        }
        if (queryConverted
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_POWER_SLIDE_DOOR)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_POWER_SLIDE_DOOR);
        }
        if (queryConverted
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_KEYLESS_ENTRY)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_KEYLESS_ENTRY);
        }
        if (queryConverted
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SMART_KEY)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SMART_KEY);
        }
        if (queryConverted
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_CRUISE_CTRL)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_CRUISE_CTRL);
        }
        if (queryConverted
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_AUTO_BRAKE)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_AUTO_BRAKE);
        }
        if (queryConverted
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SUPPORT_PEDAL)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SUPPORT_PEDAL);
        }
        if (queryConverted
          .includes(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SUPPORT_LANE)) {
          condition.option.push(NEWCAR_CONST.SEARCH_OPTION.ID.HAS_SUPPORT_LANE);
        }
      }
      if (query.term) {
        condition.term = Number(query.term);
      }
      if (query.maker) {
        condition.maker = ([query.maker].flat() as CARMO_CONST.MAKER.ID[]);
      }
      if (query.body) {
        condition.body = ([query.body].flat() as CARMO_CONST.BODY_TYPE.ID[]);
      }
      if (query.price) {
        condition.price = ([query.price].flat() as NEWCAR_CONST.PRICE.ID[]);
      }
      if (query.cap) {
        condition.capacity = ([query.cap].flat() as NEWCAR_CONST.CAPACITY.ID[]);
      }
    }

    // let isSameURI = false;
    // if (searchCarStore.searchHistory.length > 0) {
    //   // 前回条件の取り出し
    //   const last = searchCarStore.searchHistory.slice(-1)[0];
    //   // 前回と同じ条件での検索で結果が存在の場合、前回値を設定で終了する。
    //   isSameURI = (last.uri === location.href);
    // }
    // if (!isSameURI) {
    //   if (condition.word === undefined) {
    //     // 通常の検索時
    //     await searchCarStore.searchCondition(condition);
    //   } else {
    //     // ワード検索時
    //     await searchCarStore.searchWord(condition);
    //   }
    // } else await searchCarStore.search(condition);

    await searchCarStore.search(condition);
    setResultList(searchCarStore.resultList);

    // MetaのTitle、Descriptionを更新
    const title = NEWCAR_DEFINE.META.LINEUP_TITLE(condition.maker, condition.body, orderby);
    const description = NEWCAR_DEFINE.META.LINEUP_DESCRIPTION(
      condition.maker, condition.body, orderby,
    );
    CARMO_UTIL.setMeta(title, description);
    CARMO_UTIL.setOgp({ property: 'title', content: title });
    CARMO_UTIL.setOgp({ property: 'description', content: description });
    CARMO_UTIL.setTwitterOgp({ name: 'title', content: title });
    CARMO_UTIL.setTwitterOgp({ name: 'description', content: description });

    // Criteo計測タグ発火イベント
    GTM.dataLayer.push({
      event: 'criteo.activate',
      criteoProductIDList: (resultList || []).slice(0, 3).map((car) => `${car.id__normalized || ''}`),
    });

    /** SEOコンテンツ取得 */
    const nowUrl = location.pathname.replace(/^\/+/, '').replace(/\/$/, '');
    // URLに対応するデータがある場合、SEOコンテンツを表示
    if (seoDataExists(nowUrl)) {
      setSeoData(fetchData(nowUrl));
    }

    // 詳細検索ダイアログの選択状態を更新
    // if(refs && refs.detailCondition){
    //   (refs.detailCondition & { bindSearchCondition: () => void }).bindSearchCondition();
    // }
  };

  useEffect(() => {
    onUrlChange();
    setTimeout(() => {
      setIsSearched(true);
    }, 1000);
  }, [location.href]);

  /* モーダル操作 */
  const [modal, setModal] = useState(false);
  const openModal = (): void => setModal(true);
  const closeModal = (): void => setModal(false);

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div>
        <Header h1Text={seoData.topText} />
        <CarmoBreadcrumb makerId={makerId} bodyId={bodyType} isList />
        <div className={`has-background-white-ter ${styles.isBorderTopLine}`}>
          <div className="columns is-mobile is-multiline">
            {/** PC用 */}
            <div id="search-result-header-pc" className="is-hidden-touch column is-2-desktop is-margin-5">
              <SearchResultHeader
                isMakerOnly={false}
                makerId={makerId}
                isBodyOnly={false}
                bodyType={bodyType}
                seoTopText={seoData.topText}
                openModal={openModal}
              />
              {/* 上部SEOテキスト */}
              { seoData.topText && (
                <h1 className="is-size-4 has-text-weight-semibold is-margin-bottom-4">{seoData.topText}</h1>
              )}
              <SearchResultDesktop
                isMakerOnly={false}
                makerId={makerId}
                isBodyOnly={false}
                bodyType={bodyType}
                openModal={openModal}
                isOrder={headerOrder}
                setIsOrder={setHeaderOrder}
              />
            </div>

            {/** SP用 */}
            <div id="search-result-header-sp" className="is-hidden-desktop column is-margin-0 is-full-touch">
              <SearchResultHeader
                isMakerOnly={false}
                makerId={makerId}
                isBodyOnly={false}
                bodyType={bodyType}
                seoTopText={seoData.topText}
                openModal={openModal}
              />
            </div>
            <main className="column is-full-touch container">
              <div id="ab-202408-banner" className={`${styles.ab202408Banner} is-hidden-tablet is-hidden`}>
                <img src={ab202408Banner} alt="定額カルモくんなら選べる！ラク！レスが早い！" />
                <p className={`${styles.ab202408BannerNote} has-text-right`}>※1）7年以上ご契約でもらえるオプション加入時 ※2）通常のご案内時。最大3営業日</p>
              </div>
              <div className={`${styles.panelTopSpace} is-hidden-mobile`} />
              {/** 検索結果一覧 */}
              <div className="columns is-multiline">
                {!isSearched
                  ? [...Array(12).keys()].map((val) => (
                    <div className="column is-3-widescreen is-4-tablet" key={val}>
                      {/** 検索が終わるまではスケルトンを表示する */}
                      <CarModelPanelSkeletonScreen />
                    </div>
                  ))
                  : resultList.map((result: CarDigest, index) => (
                    <div className="column is-3-widescreen is-4-tablet" key={index.toString()}>
                      <CarModelPanel
                        digest={result}
                        isMinPrice={minPriceCarIds.includes(result.id__normalized)}
                      />
                    </div>
                  ))}
              </div>

              {/** 検索結果0件表示 */}
              {(isSearched && resultList.length === 0) && (
                <div className={`is-margin-5 box ${styles.contentsBox}`}>
                  <EmptyStateLineupPage />
                </div>
              )}

              {/* 口コミ点数 */}
              <ReviewsLineup />

              {/* 三冠 */}
              <div className={`is-margin-top-7 is-margin-5 box ${styles.contentsBox}`}>
                <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-1">国内No.1カーリース</h2>
                <figure className="is-margin-bottom-4 has-text-centered">
                  <img className={styles.sankanDisplay} src={tripleCrown} loading="lazy" alt="三冠獲得" width="293" />
                </figure>
                <div className="is-margin-bottom-6 has-text-centered">
                  <img src={tripleCrownCarmoLogo} alt="お得にマイカー 定額カルモくん" width="164" />
                </div>
                <div className={`has-text-centered is-hidden-mobile ${styles.sankanArea}`}>
                  <img className={`${styles.sankanItem}`} src={reasonRecommend} alt="カーリース利用経験者が選ぶ、おすすめしたいと思うカーリース No.1" />
                  <img className={`${styles.sankanItem}`} src={reasonSupport} alt="カーディーラー勤務者が選ぶサポートが充実していると思うカーリース No.1" />
                  <img className={`${styles.sankanItem}`} src={reasonCost} alt="ファイナンシャルプランナーが選ぶ、コストパフォーマンスが高いと思うカーリースNo.1" />
                </div>
                <div className={`has-text-centered is-hidden-tablet ${styles.sankanArea}`}>
                  <img className={`is-margin-bottom-4 ${styles.sankanItemSp}`} src={reasonRecommendSp} alt="カーリース利用経験者が選ぶ、おすすめしたいと思うカーリース No.1" />
                  <img className={`is-margin-bottom-4 ${styles.sankanItemSp}`} src={reasonSupportSp} alt="カーディーラー勤務者が選ぶサポートが充実していると思うカーリース No.1" />
                  <img className={`is-margin-bottom-4 ${styles.sankanItemSp}`} src={reasonCostSp} alt="ファイナンシャルプランナーが選ぶ、コストパフォーマンスが高いと思うカーリースNo.1" />
                </div>
                <p className="has-text-grey is-size-7 is-padding-right-4 is-padding-left-4 is-margin-bottom-4">
                  2024年1月期_ブランドのイメージ調査（調査1～3） 調査機関：日本マーケティングリサーチ機構 調査期間：2023年12月14日～2024年1月9日 n数：227（※調査1）、103（※調査2）、177（※調査3）/調査方法：Webアンケート 調査対象者：https://jmro.co.jp/r01525/ 備考：本調査は個人のブランドに対するイメージを元にアンケートを実施し集計しております。/本ブランドの利用有無は聴取しておりません。/効果効能等や優位性を保証するものではございません。/競合2位との差は5％以上。
                </p>
              </div>

              {/* 提携先 */}
              <div className={`is-margin-top-7 is-margin-5 box ${styles.contentsBox}`}>
                <h2 className="title is-size-4 has-text-centered is-margin-top-3 is-margin-bottom-4">提携先</h2>
                <div className=" has-text-centered">
                  <img src={orix} alt="オリックス自動車" width="250px" />
                </div>
                <div className="sp-border is-padding-4 is-margin-top-4">
                  <p>おトクにマイカー 定額カルモくんは、オリックス自動車株式会社との提携サービスとなります。</p>
                  <p>お客様にお届けする新車は、すべてオリックス自動車が扱う正規ディーラー車です。</p>
                </div>
              </div>

              {/** SEO SP用 */}
              { seoData.headLine && seoData.body && (
                <div className="is-hidden-desktop is-margin-5">
                  <Accordion
                    headLine={seoData.headLine}
                    seoBody={seoData.body}
                  />
                </div>
              ) }
            </main>

            {/** 検索結果フッター */}
            <SearchResultFooter
              openModal={openModal}
              isOrder={headerOrder}
              setIsOrder={setHeaderOrder}
            />
            <CarmoFooter />

            {/** SEO PC用 */}
            { seoData.headLine && seoData.body && (
              <div className={`is-hidden-touch is-margin-7 is-padding-7 has-background-white has-text-grey-dark box ${styles.seoBox} ${styles.radiusMini}`}>
                <h2 className="is-size-5 has-text-weight-semibold">{seoData.headLine}</h2>
                <div className="is-margin-top-6 is-size-6">
                  <p>{seoBaseText}</p>
                  <p>{seoData.body}</p>
                </div>
              </div>
            )}
          </div>

          {/** モーダル */}
          <div data-type="custom-parent" className={`modal search-dialog ${styles.pointerEventsAuto} ${modal ? 'is-active' : styles.close}`}>
            <div className="modal-background" onClick={() => closeModal()} />
            <div className={`modal-card ${styles.pointerEventsAuto}`}>
              <ModalDetailCondition closeModal={closeModal} />
            </div>
          </div>

        </div>
      </div>
      <Footer className={`${styles.isLineupFooter}`} />
      <ScrollToTop className={`${styles.isLineupPageToTop}`} />
    </Layout>
  );
};

export default TemplatePage;
